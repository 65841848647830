import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

const cToken = "insuranceFrontendToken";
const cUserDetail = "insuranceFrontendUserDetail";
const cAgentProductMappingDTO = "agentProductMappingDTO";
const cLeadDTO = "leadDTO";

// ------------------------------------ Set in Cookies and localstorage ------------------------------------

export const setTokenInCookie = (token) => {
  // Cookies.set(cToken, token);
  localStorage.setItem(cToken, token);
};

export const setUserDetailInCookie = (userDetail) => {
  // Cookies.set(cUserDetail, userDetail);
  localStorage.setItem(cUserDetail, JSON.stringify(userDetail));
};

export const setAgentProductMappingDTOInCookie = (agentProductMappingDTO) => {
  localStorage.setItem(
    cAgentProductMappingDTO,
    JSON.stringify(agentProductMappingDTO)
  );
};

export const setLeadDTOInCookie = (leadDTO) => {
  localStorage.setItem(cLeadDTO, JSON.stringify(leadDTO));
};

// ------------------------------------ Get in Cookies and localstorage ------------------------------------

export const getTokenFromCookie = () => {
  const token = localStorage.getItem(cToken);
  // const token = Cookies.get(cToken);
  if (token === undefined || token === null) {
    return;
  } else {
    return token;
  }
};

export const getUserDetailFromCookie = () => {
  const userDetail = localStorage.getItem(cUserDetail);
  // const userDetail = Cookies.get(cUserDetail);

  if (userDetail === undefined || userDetail === null) {
    return;
  } else {
    return JSON.parse(userDetail);
  }
};

export const getAgentProductMappingDTOFromCookie = () => {
  const agentProductMappingDTO = localStorage.getItem(cAgentProductMappingDTO);
  if (agentProductMappingDTO === undefined || agentProductMappingDTO === null) {
    return;
  } else {
    return JSON.parse(agentProductMappingDTO);
  }
};

export const getLeadDTOFromCookie = () => {
  const leadDTO = localStorage.getItem(cLeadDTO);
  if (leadDTO === undefined || leadDTO === null) {
    return;
  } else {
    return JSON.parse(leadDTO);
  }
};

// ------------------------------------ Remove in Cookies and localstorage ------------------------------------

export const removeCookie = () => {
  // Cookies.remove(cToken);
  // Cookies.remove(cUserDetail);

  // remove
  // localStorage.removeItem('myData');
  // remove all
  localStorage.clear();
};

// ------------------------------------ Cookies and localstorage utility methods ------------------------------------

export const isLoggedIn = () => {
  const token = localStorage.getItem(cToken);
  // const token = Cookies.get(cToken);
  if (token === undefined || token === null) {
    return false;
  } else {
    return true;
  }
};

// -------------------------------------------------------------------------------------------------
