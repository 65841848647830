import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Routes } from "react-router-dom";
import RouterComponent from "./router";

import "./App.scss";
import {
  consumeException,
  isConsumed,
  getResponseError,
} from "./utility/error-utils";

import { createStructuredSelector } from "reselect";
import { injectReducer, injectSaga } from "redux-inject-reducer-and-saga";
import { connect } from "react-redux";
import { compose } from "redux";

import History from "./utility/history-utils";
import { store } from "./configuration/store";
import ModalBox from "./configuration/ModalBox";

import { ActionTypes } from "./configuration/constant/constant";
import UserReducer from "./configuration/reducer/root";
import UserSaga from "./configuration/sagas/root";
import * as selectors from "./configuration/selector/root";

import "react-toastify/dist/ReactToastify.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Loader from "./components/Helper/Loader";

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  showLoading = () => {
    if (this.props.isLoading) {
      return <Loader />;
    }
  };

  showError = () => {
    if (
      this.props.exception &&
      !isConsumed(this.props.exception)
      //  && this.errorCodes.indexOf(this.props.exception.errorCode) != -1
    ) {
      return (
        <ModalBox
          title="Error"
          success="Ok"
          content={getResponseError(this.props.exception)}
          onSuccessClick={() => {}}
          onCancelClick={() => {}}
        />
      );
      consumeException(this.props.exception);
    }
  };

  render() {
    const { isUserAuthenticated } = this.props;

    return (
      <Router history={History} store={store}>
        <Switch>
          <Route
            render={(props) => (
              <RouterComponent
                {...props}
                isUserAuthenticated={isUserAuthenticated}
              />
            )}
            path="/"
          />
        </Switch>
        {this.showError()}
        {this.showLoading()}
      </Router>
    );
  }
}

const mapStateToProps = () => {
  return createStructuredSelector({
    isUserAuthenticated: selectors.isUserAuthenticated(),
    isLoading: selectors.isLoading(),
    exception: selectors.getException(),
  });
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const withReducer = injectReducer({ key: "user", reducer: UserReducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: "user", saga: UserSaga });

export default compose(withReducer, withSaga, withConnect)(index);
